declare const window: any;
window.dataLayer = window.dataLayer || [];
/* eslint-disable no-console */
export const gtmTracking = (propertyObj, type) => {
  console.log('propertyObj:' + propertyObj);
  console.log('page type:' + type);
  if (propertyObj && Object.keys(propertyObj).length > 0) {
    if (type === 'page') {
      console.log('in if');
      const name =
        propertyObj.category && propertyObj.name
          ? propertyObj.category + ' ' + propertyObj.name
          : propertyObj.name;
      propertyObj.event = 'Loaded a Page';
      if (name) {
        propertyObj.event = 'Viewed ' + name + ' Page';
      }
    } else {
      console.log('in else');
      propertyObj.event = propertyObj.name || propertyObj.trackEventName || '';
    }
    window.dataLayer.push(propertyObj);
  }
};
